import { originalTokkoApi } from "../originalTokkoApi.js"

import { getArrayOperationById, getSumaryFilterDefault } from "../../helpers/helper.filters.jsx" 

//Params global filters
const summary_filters = (JSON.stringify({
  "current_localization_id":[], 
  "current_localization_type": "",
  // "currency":"",
  "price_from":0,
  "price_to":9999999999999,
  "operation_types":[1,2,3],
  "property_types":[]
}))

const propertyExtended = originalTokkoApi.injectEndpoints({
  endpoints: builder => ({
    getTotalFilters: builder.query({
      query: ({API_KEY,OPERATION='',TYPE=[]}) =>({
        url: `property/get_search_summary/?key=${API_KEY}&lang=es_ar&format=json&data=${encodeURI(getSumaryFilterDefault({
          "operation":(OPERATION === '' ? [1,2,3] : getArrayOperationById(OPERATION)),
          "property_types":TYPE.map(t => t )}))}`,
        method: 'GET',
      }), 
    })
  }),
  overrideExisting: false,
})


export const { useGetTotalFiltersQuery} = propertyExtended
